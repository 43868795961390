<!-- Modal -->
<template>
    <div class="modal fade" id="createEventModal" ref="createEventModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog" :class="mediaLibOpened ? 'modal-xl' : 'modal-lg'">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span class="d-inline-flex">
                            <div v-if="!editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Create Event</h5>
                                <p class="col-12 mb-0">This will be the text that will display in the calendar box</p>
                            </div>
                            <div v-if="editing" class="row">
                                <h5 class="modal-title col-12" id="modal-title">Edit "{{ sliceString(hasHeyGovEvents ? eventWithUuid.name : event.title) }}" event</h5>
                            </div>
                        </span>
                    </div>
                </div>

                <p class="text-center mt-3" v-show="(editing && !hasHeyGovEvents && !event.title) || (editing && hasHeyGovEvents && !eventWithUuid.name)">Loading event details <i class="fa fa-spinner fa-spin"></i></p>

                <form v-show="!editing || (!hasHeyGovEvents && editing && event.title) || (hasHeyGovEvents && editing && eventWithUuid.name)" @submit.prevent="storeNewEvent">
                    <div v-show="sendError" class="alert alert-danger" role="alert">Error: Post is not saved.</div>
                    <div class="modal-body" id="modal-body">
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group mb-4">
                                            <label for="event-title" class="mb-2">Event Name</label>
                                            <input type="text" class="form-control mb-4" v-model="title" required id="event-title" placeholder="Name of the Event" />

                                            <div v-if="hasHeyGovEvents">
                                                <label for="event-status" class="mb-2">Status</label>
                                                <select class="form-control mb-4" required v-model="hgEventStatus" id="event-status">
                                                    <option v-for="status in hgEventStatuses" :value="status" v-html="status" :key="status"></option>
                                                </select>
                                            </div>

                                            <div v-if="!hasHeyGovEvents">
                                                <label for="event-category" class="mb-2">Category</label>
                                                <select class="form-control mb-4" required v-model="category" id="event-category">
                                                    <option v-for="category in categories" :value="category.id" v-html="category.name" :key="category.id"></option>
                                                </select>
                                            </div>
                                            <div v-else>
                                                <div v-if="hgCategories && hgCategories.length > 0">
                                                    <label for="hg-categories" class="mb-2"> Category </label>
                                                    <select class="form-select mb-4 overflow-hidden" v-model="hgSelectedCategories" id="hg-categories" multiple :size="isStuff ? hgCategories.length + 2 : hgCategories.length + 1">
                                                        <option class="rounded p-1" value="">
                                                            {{ hgSelectedCategories.length ? "Reset" : "Select categories" }}
                                                        </option>
                                                        <option
                                                            class="rounded p-1"
                                                            v-for="category in hgCategories"
                                                            :key="`event-category-${category.id}`"
                                                            :value="category.id"
                                                            :class="hgSelectedCategories.length && hgSelectedCategories.includes(category.id) ? 'hg-event-category' : ''"
                                                        >
                                                            {{ category.name }}
                                                        </option>
                                                        <option v-if="(siteType == 'wordpress' && isStuff) || (siteType == 'cms6' && userHasCms6Permission('create_event_categories'))" @click="openCategoryModal" class="rounded p-1" value="">Add new category</option>
                                                    </select>
                                                </div>
                                                <div v-else class="row p-1">
                                                    <div class="col text-muted text-center pt-3">
                                                        <p>You don't have event categories yet</p>
                                                        <small>*Please contact TownWeb support if you want to add event category</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Event status, default public -->
                                            <!--    <div>
                        <label for="event-status" class="mb-2">Status</label>
                        <select
                          class="form-control mb-4"
                          required
                          v-model="hgStatus"
                          id="event-status"
                        >
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </select>
                      </div> -->
                                        </div>
                                    </div>

                                    <div v-if="hasHeyGovEvents" class="col-xl-6">
                                        <label class="mb-sm-3 justify-content-center">Featured Image</label>
                                        <div class="row flex-grow-1 align-items-center mb-sm-3">
                                            <div :class="hasHeyGovEvents ? 'col' : 'col-xl-6 d-flex flex-column'" class="mb-3 d-flex justify-content-center">
                                                <!-- Upload image to hg cloud storage -->
                                                <div class="position-relative">
                                                    <label for="hg-image-upload" class="tw-modal-featured-img cursor-pointer justify-content-center">
                                                        <span v-if="hgEventImage && hgEventImage.url" class="rounded rounded-1">
                                                            <img :src="hgEventImage.url" :alt="event.name" height="175" width="188" class="rounded-3" />
                                                        </span>
                                                        <span v-else class="d-flex align-items-center justify-content-center">
                                                            <span v-html="defaultImage"></span>
                                                        </span>
                                                    </label>
                                                    <div
                                                        v-if="imageUploads"
                                                        id="tw-modal-featured-img-loading-overlay"
                                                        class="position-absolute opacity-25 bg-dark top-0 start-0 end-0 bottom-0 opacity-75 tw-light-grey-bg-color d-flex align-items-center justify-content-center"
                                                    >
                                                        <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                                    </div>
                                                    <div
                                                        v-if="hgEventImage"
                                                        id="tw-modal-featured-img-overlay"
                                                        class="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center cursor-pointer"
                                                        @mouseover="hoverOverlay = true"
                                                        @mouseleave="hoverOverlay = false"
                                                        :class="{
                                                            'tw-modal-featured-img-overlay-hovered': hoverOverlay,
                                                        }"
                                                    >
                                                        <div class="d-flex">
                                                            <label for="hg-image-upload" class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 me-1 cursor-pointer">
                                                                <i class="fas fa-image fa-2x text-white"></i>
                                                            </label>
                                                            <span class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 ms-1 cursor-pointer" @click="deleteHgImage(eventWithUuid?.uuid ?? eventWithUuid.id)">
                                                                <i class="fas fa-trash fa-2x text-white"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="file" class="d-none" id="hg-image-upload" @input="handleFileUpload($event)" />
                                            </div>
                                        </div>

                                        <!-- //TODO Adding image link  -->
                                        <!--  <div class="form-group">
                                                <label for="hg-image-link" class="mb-2"
                                                >Image link</label
                                                >
                                                <input
                                                type="text"
                                                class="form-control mb-4"
                                                v-model="hgImageLink"
                                                id="hg-image-link"
                                                placeholder="Add image link"
                                                />
                                            </div> -->
                                    </div>
                                    <div v-else class="col-xl-6 d-flex flex-column">
                                        <label class="mb-sm-3">Featured Image</label>
                                        <div class="row flex-grow-1 align-items-center mb-sm-3">
                                            <div class="col-md-6 mb-3 d-flex justify-content-center">
                                                <div class="position-relative">
                                                    <label for="featured_media" class="d-flex tw-modal-featured-img cursor-pointer align-items-center justify-content-center">
                                                        <!-- <img v-if="featuredMedia" :src="featuredMedia && featuredMedia.url || defaultImage" :alt="event.post_title || event.title" height="175" width="188"> -->
                                                        <img
                                                            v-if="featuredMedia"
                                                            :src="
                                                                featuredMedia && featuredMedia.media_details && featuredMedia.media_details.sizes && featuredMedia.media_details.sizes.length
                                                                    ? featuredMedia.media_details.sizes.thumbnail.source_url
                                                                    : featuredMedia.source_url || featuredMedia.url || defaultImage
                                                            "
                                                            :alt="event.post_title || event.title"
                                                            height="175"
                                                            width="188"
                                                        />
                                                        <span v-else class="d-flex align-items-center justify-content-center">
                                                            <span v-html="defaultImage"></span>
                                                        </span>
                                                    </label>

                                                    <div
                                                        v-if="imageUploads"
                                                        id="tw-modal-featured-img-loading-overlay"
                                                        class="position-absolute opacity-25 bg-dark top-0 start-0 end-0 bottom-0 opacity-75 tw-light-grey-bg-color d-flex align-items-center justify-content-center"
                                                    >
                                                        <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                                    </div>
                                                    <div
                                                        v-if="featuredMedia"
                                                        id="tw-modal-featured-img-overlay"
                                                        class="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center cursor-pointer"
                                                        @mouseover="hoverOverlay = true"
                                                        @mouseleave="hoverOverlay = false"
                                                        :class="{
                                                            'tw-modal-featured-img-overlay-hovered': hoverOverlay,
                                                        }"
                                                    >
                                                        <div class="d-flex">
                                                            <label for="featured_media" class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 me-1 cursor-pointer">
                                                                <i class="fas fa-image fa-2x text-white"></i>
                                                            </label>
                                                            <span
                                                                class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 ms-1 cursor-pointer"
                                                                @click="
                                                                    event.featured_media = null;
                                                                    featuredMedia = null;
                                                                "
                                                            >
                                                                <i class="fas fa-trash fa-2x text-white"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="file" class="d-none" id="featured_media" @change="handleFileUpload($event)" />
                                            </div>
                                            <div class="col-md-6 mb-3 d-none d-md-flex justify-content-center">
                                                <div class="tw-modal-media-lib tw-light-grey-bg-color d-flex align-items-center justify-content-center cursor-pointer" @click="mediaLib($event, chooseFeaturedImg)">
                                                    <div class="tw-modal-media-lib-btn tw-grey-bg-color d-flex align-items-center text-white">
                                                        <i class="fas fa-plus"></i>
                                                        <span class="ms-2">Media Library</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="row mb-2">
                                        <label class="col-md-6 d-flex align-items-center" for="post-content">Description</label>
                                    </div>
                                    <editor api-key="" v-model="eventDescription" theme="advanced" theme_advanced_toolbar_location="bottom" :init="tinymcInit" @execCommand="tinyMCEMedLib" />
                                </div>

                                <div class="form-group">
                                    <label class="mt-3 mb-2">Event Time</label>
                                    <div class="row">
                                        <div class="col-lg-6 mb-3">
                                            <div class="row">
                                                <div class="col-6 mb-2 mb-sm-0">
                                                    <label id="tw-create-modal-schedule-from" class="tw-create-modal-schedule p-2 position-relative w-100 cursor-pointer">
                                                        <div>
                                                            <small>Event start</small>
                                                        </div>
                                                        <div class="position-relative">
                                                            <i class="fas fa-calendar"></i>
                                                            <Datepicker
                                                                class="tw-create-post-modal-date-from position-absolute"
                                                                menuClassName="tw-create-post-modal-date-from-menu"
                                                                teleport="#tw-create-modal-schedule-from"
                                                                inputClassName="border-0 bg-transparent"
                                                                :format="formatDate"
                                                                altPosition
                                                                hideInputIcon
                                                                v-model="start_date"
                                                                placeholder=""
                                                                weekStart="0"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="col-6">
                                                    <label id="tw-create-modal-schedule-from-time" class="tw-create-modal-schedule p-2 position-relative w-100 cursor-pointer" :class="allDay && 'opacity-50'">
                                                        <div>
                                                            <small>Time</small>
                                                        </div>
                                                        <div class="position-relative">
                                                            <i class="fas fa-clock"></i>
                                                            <Datepicker
                                                                class="tw-create-post-modal-time-from position-absolute"
                                                                menuClassName="tw-create-post-modal-time-from-menu"
                                                                teleport="#tw-create-modal-schedule-from-time"
                                                                inputClassName="border-0 bg-transparent"
                                                                :format="formatTime"
                                                                altPosition
                                                                hideInputIcon
                                                                v-model="start_time"
                                                                :timePicker="true"
                                                                :is24="false"
                                                                placeholder=""
                                                                :disabled="allDay"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mb-3">
                                            <div class="row">
                                                <div class="col-6 mb-2 mb-sm-0">
                                                    <label id="tw-create-modal-schedule-to" class="tw-create-modal-schedule p-2 position-relative w-100 cursor-pointer">
                                                        <div>
                                                            <small>Event ends</small>
                                                        </div>
                                                        <div class="position-relative">
                                                            <i class="fas fa-calendar"></i>
                                                            <Datepicker
                                                                class="tw-create-post-modal-date-to position-absolute"
                                                                menuClassName="tw-create-post-modal-date-to-menu"
                                                                teleport="#tw-create-modal-schedule-to"
                                                                inputClassName="border-0 bg-transparent"
                                                                :format="formatDate"
                                                                altPosition
                                                                hideInputIcon
                                                                v-model="end_date"
                                                                placeholder=""
                                                                weekStart="0"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="col-6">
                                                    <label id="tw-create-modal-schedule-to-time" class="tw-create-modal-schedule p-2 position-relative w-100 cursor-pointer" :class="allDay && 'opacity-50'">
                                                        <div>
                                                            <small>Time</small>
                                                        </div>
                                                        <div class="position-relative">
                                                            <i class="fas fa-clock"></i>
                                                            <Datepicker
                                                                class="tw-create-post-modal-time-to position-absolute"
                                                                menuClassName="tw-create-post-modal-time-to-menu"
                                                                teleport="#tw-create-modal-schedule-to-time"
                                                                inputClassName="border-0 bg-transparent"
                                                                :format="formatTime"
                                                                altPosition
                                                                hideInputIcon
                                                                v-model="end_time"
                                                                :timePicker="true"
                                                                :is24="false"
                                                                placeholder=""
                                                                :disabled="allDay"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tw-checkbox col d-flex align-items-center">
                                        <span v-if="hasHeyGovEvents && !hideEndTime">
                                            <input id="tw-event-all-day" type="checkbox" v-model="allDay" />
                                            <label class="fw-normal" for="tw-event-all-day"><small>All day</small></label>
                                        </span>
                                        <span v-else-if="!hasHeyGovEvents">
                                            <input id="tw-event-all-day" type="checkbox" v-model="allDay" />
                                            <label class="fw-normal" for="tw-event-all-day"><small>All day</small></label>
                                        </span>
                                        <span v-if="hasHeyGovEvents && !allDay" class="ps-2">
                                            <input id="tw-event-hide-end-time" type="checkbox" v-model="hideEndTime" />
                                            <label class="fw-normal" for="tw-event-hide-end-time"><small>Hide event end time </small></label>
                                        </span>
                                        <span v-if="hasHeyGovEvents" class="ps-2">
                                            <input id="tw-event-hide-location" type="checkbox" v-model="hideLocation" />
                                            <label class="fw-normal" for="tw-event-hide-location"><small>Hide location</small></label>
                                        </span>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="form-group col col-lg-3">
                                            <label class="mb-2" for="event-repeat">Recurrence</label>
                                            <select class="form-select tw-recurrence-select" v-model="recurrenceType" id="event-repeat">
                                                <option value="">No recurrence</option>
                                                <option :value="!hasHeyGovEvents ? 'Daily' : 'daily'">Daily</option>
                                                <option :value="!hasHeyGovEvents ? 'Weekly' : 'weekly'">Weekly</option>
                                                <option :value="!hasHeyGovEvents ? 'Monthly' : 'monthly'">Monthly</option>
                                                <option :value="!hasHeyGovEvents ? 'Yearly' : 'yearly'">Yearly</option>
                                            </select>
                                        </div>
                                        <div v-show="recurrenceType" class="form-group col-lg-2">
                                            <label class="mb-2" for="event-repeats2">Interval</label>
                                            <select class="form-select tw-recurrence-select" v-model="recurrenceInterval" id="event-repeats2">
                                                <option value="1">{{ recurrenceType }}</option>
                                                <option value="2">2 {{ intervalLabels[recurrenceType] }}</option>
                                                <option value="3">3 {{ intervalLabels[recurrenceType] }}</option>
                                                <option value="4">4 {{ intervalLabels[recurrenceType] }}</option>
                                                <option value="5">5 {{ intervalLabels[recurrenceType] }}</option>
                                            </select>
                                        </div>
                                        <div v-show="recurrenceType == 'Weekly' || recurrenceType == 'weekly'" class="form-group col-lg-6">
                                            <label class="mb-2" for="event-repeats2">On</label><br />
                                            <div class="btn-group" role="group" aria-label="Weekly group">
                                                <button type="button" class="btn btn-outline-info" :class="d7 && 'active text-white'" @click="d7 = !d7">S</button>
                                                <button type="button" class="btn btn-outline-info" :class="d1 && 'active text-white'" @click="d1 = !d1">M</button>
                                                <button type="button" class="btn btn-outline-info" :class="d2 && 'active text-white'" @click="d2 = !d2">T</button>
                                                <button type="button" class="btn btn-outline-info" :class="d3 && 'active text-white'" @click="d3 = !d3">W</button>
                                                <button type="button" class="btn btn-outline-info" :class="d4 && 'active text-white'" @click="d4 = !d4">T</button>
                                                <button type="button" class="btn btn-outline-info" :class="d5 && 'active text-white'" @click="d5 = !d5">F</button>
                                                <button type="button" class="btn btn-outline-info" :class="d6 && 'active text-white'" @click="d6 = !d6">S</button>
                                            </div>
                                        </div>
                                        <div v-show="recurrenceType == 'Monthly' || recurrenceType == 'monthly'" class="form-group col-lg-3">
                                            <label class="mb-2" for="event-month-on">On</label>
                                            <select class="form-select tw-recurrence-select" v-model="sameDay" id="event-month-on">
                                                <option value="yes">the same date</option>
                                                <option value="no">a different date</option>
                                            </select>
                                        </div>
                                        <div v-if="!hasHeyGovEvents ? recurrenceType == 'Monthly' && sameDay == 'no' : recurrenceType == 'monthly' && sameDay == 'no'" class="form-group col-lg-2">
                                            <label class="mb-2" for="event-month-on-day">&nbsp;</label>
                                            <select class="form-select tw-recurrence-select" v-model="monthNumber" id="event-month-on-day">
                                                <option :value="!hasHeyGovEvents ? 'First' : 'first'">First</option>
                                                <option :value="!hasHeyGovEvents ? 'Second' : 'second'">Second</option>
                                                <option :value="!hasHeyGovEvents ? 'Third' : 'third'">Third</option>
                                                <option :value="!hasHeyGovEvents ? 'Fourth' : 'fourth'">Fourth</option>
                                                <option :value="!hasHeyGovEvents ? 'Last' : 'last'">Last</option>
                                            </select>
                                        </div>
                                        <div v-if="!hasHeyGovEvents ? recurrenceType == 'Monthly' && sameDay == 'no' : recurrenceType == 'monthly' && sameDay == 'no'" class="form-group col-lg-2">
                                            <label class="mb-2" for="event-month-on-day">&nbsp;</label>
                                            <select class="form-select tw-recurrence-select" v-model="monthDay">
                                                <option v-if="!hasHeyGov" value="7">Sunday</option>
                                                <option v-else value="0">Sunday</option>
                                                <option value="1">Monday</option>
                                                <option value="2">Tuesday</option>
                                                <option value="3">Wednesday</option>
                                                <option value="4">Thursday</option>
                                                <option value="5">Friday</option>
                                                <option value="6">Saturday</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" v-show="recurrenceType">
                                    <div v-if="!endsAfter" class="form-group col-6 col-lg-3">
                                        <label class="mb-2"> Recurrence end on </label>
                                        <label id="tw-create-modal-recurrence-end" class="tw-create-modal-schedule p-2 position-relative w-100 cursor-pointer">
                                            <div class="position-relative">
                                                <i class="fas fa-calendar"></i>
                                                <Datepicker
                                                    class="tw-create-modal-date-recurrence-end position-absolute w-100"
                                                    menuClassName="tw-create-event-modal-date-recurrence-end"
                                                    teleport="#tw-create-modal-recurrence-end"
                                                    inputClassName="border-0 bg-transparent"
                                                    :format="formatDate"
                                                    altPosition
                                                    hideInputIcon
                                                    v-model="recurrenceEnd"
                                                    placeholder=""
                                                    weekStart="0"
                                                />
                                            </div>
                                        </label>
                                        <p class="col m-0 d-flex align-items-center">
                                            <small><i>(blank - 2 years from start date)</i></small>
                                        </p>
                                    </div>
                                    <div v-if="hasHeyGovEvents && !recurrenceEnd" class="col-6 col-lg-3">
                                        <div class="form-group">
                                            <label for="events-ends-after" class="mb-2">Ends after</label>
                                            <input id="events-ends-after" type="number" min="1" v-model="endsAfter" class="form-control tw-number-input-field" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-3" v-if="hasHeyGovWithVenues && heyGovVenues.length">
                                    <div class="form-group col">
                                        <label class="mb-2" for="event-repeat">HeyGov Venue</label>
                                        <Multiselect
                                            v-model="heyGovBlockedVenues"
                                            mode="tags"
                                            class="tw-delivery-method-select"
                                            :close-on-select="false"
                                            :searchable="false"
                                            :create-option="false"
                                            :hideSelected="false"
                                            :options="heyGovVenues"
                                            placeholder="-- Choose Venues --"
                                        />
                                    </div>
                                </div>
                            </div>
                            <MediaLibraryInModal ref="mediaLibrary" :mediaLibOpened="mediaLibOpened" @mediaLibClose="mediaLibOpened = false" @setMedia="handleSetFeaturedImage" @mediaInsert="handleMediaInsert" />
                            <!-- <div class="anime d-flex" :class="mediaLibOpened ? 'col-4 collapse show' : 'collapsed'">
                                <div class="tw-create-modal-media-lib-wrapper d-flex w-100" :class="!mediaLibOpened && 'd-none'">
                                    <div class="row flex-grow-1 w-100">
                                        <div class="col mx-4 tw-light-grey-bg-color p-3">
                                            <label class="mb-3">Media Library</label>
                                            <p>A complete list of the files located on your site</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="modal-footer pt-3" :class="mediaLibOpened ? 'col-8' : 'col-12'">
                        <!-- <div v-if="supportsFb" class="tw-checkbox col d-flex align-items-center">
                            <input id="tw-fb-auto" type="checkbox" v-model="fbAuto">
                            <label class="fw-bold" for="tw-fb-auto">Enable auto publishing to my facebook account</label>
                        </div> -->
                        <div v-if="!editing || !eventWithUuid">
                            <button class="btn tw-red-bg-color text-white">
                                <span>Save</span>
                            </button>
                        </div>
                        <div v-else class="d-inline-flex">
                            <a v-if="eventWithUuid.type !== 'recurrent'" type="button" class="btn tw-red-bg-color text-white rounded rounded-pill me-2 w-auto" @click="storeNewEvent((editType = 'single'))">
                                <small>Update this event</small>
                                <span v-if="spinner"></span>
                            </a>
                            <a v-if="eventWithUuid.type !== 'single'" type="button" class="btn tw-red-bg-color text-white rounded-pill w-auto me-2" @click="storeNewEvent((editType = 'all'))">
                                <small>Update full recurrence</small>
                                <span v-if="spinner"></span>
                            </a>
                            <a v-if="eventWithUuid.type === 'recurrence'" type="button" class="btn tw-red-bg-color text-white rounded-pill w-auto" @click="storeNewEvent((editType = 'partial'))">
                                <small>Update this & following events</small>
                                <span v-if="spinner"></span>
                            </a>
                        </div>
                        <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Add/Edit category modal -->
    <div class="modal fade" id="add-new-category" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span class="d-inline-flex">
                            <div class="row">
                                <h5 class="modal-title col-12" id="modal-title">Add new category</h5>
                                <p class="col-12"></p>
                            </div>
                        </span>
                    </div>
                </div>
                <form @submit.prevent="addNewCategory">
                    <div class="row p-3">
                        <div class="col-12 mb-3">
                            <label for="category-name" class="form-label">Category name </label>
                            <input id="category-name" type="text" class="form-control rounded-pill p-2" v-model="newCategory" placeholder="Enter category name" required />
                        </div>
                        <div class="col-12 my-3">
                            <button class="tw-red-bg-color text-white py-2 px-3 rounded-pill border-0">
                                <i class="fas fa-plus"></i>
                                <span class="fs-5 ps-2">Add category</span>
                            </button>
                            <button class="btn tw-dark-grey-bg-color text-white rounded-pill float-end fs-5 cursor-pointer" type="button" @click="closeCategoryModal">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- <MediaModal ref="MediaModal" @mediaInsert="handleMediaInsert" @setMedia="handleSetFeaturedImage" /> -->
</template>
<script>
import { Modal } from "bootstrap";
import MediaLibraryInModal from "@/components/media-library/MediaLibraryInModal";
// import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import editor from "@tinymce/tinymce-vue";
import tinymceConfig from "@/tinymce/config";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import wordpressMixin from "@/mixins/wordpressMixin";
import helperMixin from "@/mixins/helperMixin";
import siteMixin from "@/mixins/siteMixin";
import heyGovMixin from "@/mixins/heyGovMixin";
import userMixin from "@/mixins/userMixin";
import Multiselect from "@vueform/multiselect";

export default {
    name: "CreateEventModal",
    components: {
        MediaLibraryInModal,
        editor,
        Multiselect,
    },
    emits: ["modalHidden", "eventSaved", "input"],
    data: (vm) => ({
        modal: null,
        toast: null,
        title: "",
        eventDescription: "",
        defaultImage: '<div class="tw-defaul-featured-img tw-light-grey-bg-color d-flex align-items-center"><i class="fas fa-image me-2"></i> <span>Upload Image</span></div>',
        featuredMedia: null,
        file: null,
        categories: null,
        category: null,
        spinner: false,
        event: {},
        hgEventStatus: "public",
        hgEventStatuses: ["public", "private"],
        chooseFeaturedImg: true,
        formatDate: "LLL dd, y",
        formatTime: "hh:mm a",
        endDateFormat: "LLL dd, y",
        mediaLibOpened: false,
        start_date: vm.moment().toDate(),
        start_time: {
            hours: vm.moment().add(2, "hours").minutes(0).hours(),
            minutes: vm.moment().add(2, "hours").minutes(0).minutes(),
        },
        end_date: vm.moment().toDate(),
        end_time: {
            hours: vm.moment().add(3, "hours").minutes(0).hours(),
            minutes: vm.moment().add(3, "hours").minutes(0).minutes(),
        },
        allDay: false,
        hideEndTime: false,
        recurrenceType: "",
        recurrenceInterval: 1,
        d1: false,
        d2: false,
        d3: false,
        d4: false,
        d5: false,
        d6: false,
        d7: false,
        sameDay: "yes",
        monthNumber: "First",
        monthDay: "7",
        recurrenceEnd: null,
        imageUploads: false,
        hoverOverlay: false,
        sendError: false,
        heyGovVenues: [],
        heyGovBlockedVenues: [],
        jurisdiction: null,
        user: null,
        hgEventImage: {
            url: "",
            path: "",
        },
        hgImageLink: "",
        hgSelectedCategories: [],
        hgCategories: [],
        permission: null,
        newCategory: "",
        editType: "single",
        eventObject: null,
        isStuff: false,
        headers: null,
        wpBasicHeaders: null,
        endsAfter: null,
        hideLocation: true,
    }),
    mounted() {
        let user = this.getCurrentUser();
        this.headers = { Authorization: "Bearer " + user.auth, "Content-Type": "application/json" };
        // Headers for wordpress
        this.wpBasicHeaders = { Authorization: "Basic " + user.auth, "Content-Type": "application/json" };
        this.permission = this.getIsStuff();
        this.getHeygovJurisdiction();
        this.modal = new Modal(this.$refs.createEventModal);
        this.$modalCategory = new Modal(document.getElementById("add-new-category"));

        this.isStuff = this.getIsStuff();
        // this.showToast()
        let createEventModal = document.getElementById("createEventModal");
        this.moment.suppressDeprecationWarnings = true;

        // Prevent Bootstrap dialog from blocking focusin
        document.addEventListener("focusin", (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        });

        let self = this;
        //hide.bs.modal
        createEventModal.addEventListener("hide.bs.modal", function () {
            let el = document.querySelector('button[aria-label="More..."][aria-expanded="true"]');
            if (el) {
                el.click();
            }
        });
        createEventModal.addEventListener("hidden.bs.modal", function () {
            getTinymce().activeEditor.ui.hide();
            self.$emit("modalHidden");
            self.event = {};
            self.title = "";
            self.hgEventStatus = 'public';
            self.eventDescription = "";
            self.featuredMedia = null;
            self.file = null;
            self.category = null;
            self.mediaLibOpened = false;
            self.start_date = self.moment().toDate();
            self.start_time = {
                hours: self.moment().add(2, "hours").minutes(0).hours(),
                minutes: self.moment().add(2, "hours").minutes(0).minutes(),
            };
            self.end_date = self.moment().toDate();
            self.end_time = {
                hours: self.moment().add(3, "hours").minutes(0).hours(),
                minutes: self.moment().add(3, "hours").minutes(0).minutes(),
            };
            self.recurrenceType = "";
            self.recurrenceInterval = 1;
            self.d1 = false;
            self.d2 = false;
            self.d3 = false;
            self.d4 = false;
            self.d5 = false;
            self.d6 = false;
            self.d7 = false;
            self.sameDay = "yes";
            self.monthNumber = "First";
            self.monthDay = "7";
            self.recurrenceEnd = null;
            self.imageUploads = false;
            self.hoverOverlay = false;
            self.sendError = false;
            self.heyGovBlockedVenues = [];
            self.hgSelectedCategories = [];
            self.hgImageLink = "";
            self.hgEventImage = null;
            self.allDay = false;
            self.hideEndTime = false;
            self.endsAfter = "";
            self.eventLocation = null;
            self.hideLocation = true;
        });
    },
    watch: {
        editingEventId() {
            if (this.editingEventId) {
                this.setEvent();
            }
        },
        // all commented lines reworked by request, but still there if someone change mind in future
        start_date(date) {
            if (date > this.end_date) {
                // if (date >= this.end_date) {

                this.end_date = this.moment(date).toDate();
                // this.end_date = this.moment(date).add(1, 'hour').toDate();

                if (this.moment(this.start_time) > this.moment(this.end_time)) {
                    // if (this.moment(this.start_time) >= this.moment(this.end_time)) {
                    this.end_time = {
                        hours: this.moment(this.start_time).hour(),
                        minutes: this.moment(this.start_time).minute(),
                    };
                    // this.end_time = { hours: this.moment(this.start_time).add(1, 'hour').hour(), minutes: this.moment(this.start_time).add(1, 'hour').minute() };
                }
            }
        },
        start_time() {
            if (this.moment(this.start_time) > this.moment(this.end_time) && !this.hasHeyGovEvents) {
                // if (this.moment(this.start_time) >= this.moment(this.end_time)) {
                this.end_time = {
                    hours: this.moment(this.start_time).hour(),
                    minutes: this.moment(this.start_time).minute(),
                };
                // this.end_time = { hours: this.moment(this.start_time).add(1, 'hour').hour(), minutes: this.moment(this.start_time).add(1, 'hour').minute() };
            }
        },
        end_date(date) {
            if (date < this.start_date) {
                // if (date <= this.start_date) {
                this.start_date = this.moment(date).toDate();
                // this.start_date = this.moment(date).subtract(1, 'hour').toDate();

                if (this.moment(this.start_time) > this.moment(this.end_time)) {
                    // if (this.moment(this.start_time) >= this.moment(this.end_time)) {
                    this.end_time = {
                        hours: this.moment(this.start_time).hour(),
                        minutes: this.moment(this.start_time).minute(),
                    };
                    // this.end_time = { hours: this.moment(this.start_time).add(1, 'hour').hour(), minutes: this.moment(this.start_time).add(1, 'hour').minute() };
                }
            }
        },
        end_time() {
            if (this.moment(this.start_time) > this.moment(this.end_time)) {
                // if (this.moment(this.start_time) >= this.moment(this.end_time)) {
                this.end_time = {
                    hours: this.moment(this.start_time).hour(),
                    minutes: this.moment(this.start_time).minute(),
                };
                // this.end_time = { hours: this.moment(this.start_time).add(1, 'hour').hour(), minutes: this.moment(this.start_time).add(1, 'hour').minute() };
            }
        },
    },
    methods: {
        showModal: function (value) {
            if (value) {
                this.modal.show();
                if (!this.hasHeyGovEvents) {
                    this.setCategories();
                }

                if (this.hasHeyGovWithVenues) {
                    // cleaning heygov venues array
                    this.heyGovVenues = [];
                    this.setHeyGovVenues();
                }
            }
        },
        async setEvent() {
            if (!this.hasHeyGovEvents) {
                let res = await this.getEvent(this.$props.editingEventId);
                this.event = await res.data;

                this.title = this.event.title;
                this.eventDescription = this.event.description;
                // console.log(this.postContent)
                // this.$refs.editor.setHTML(this.postContent);
                // this.$refs.editor.pasteHTML(this.postContent);
                if (this.event.categories.length) {
                    this.category = this.event.categories[0].id;
                }
                // this.status = this.post.status;
                this.start_date = this.event.start_date;
                this.start_time = {
                    hours: this.moment(this.event.start_date).hour(),
                    minutes: this.moment(this.event.start_date).minute(),
                };
                this.end_date = this.event.end_date;
                this.end_time = {
                    hours: this.moment(this.event.end_date).hour(),
                    minutes: this.moment(this.event.end_date).minute(),
                };

                this.allDay = this.event.all_day;

                this.featuredMedia = this.event.image;

                if (this.hasHeyGovWithVenues && this.event.heygov_venues) {
                    this.heyGovBlockedVenues = this.event.heygov_venues.split(",");
                }

                // if event is recurring
                // console.log(this.event.recurrence.rules)
                if (this.event.recurrence && this.event.recurrence.rules && this.event.recurrence.rules.length) {
                    this.recurrenceEnd = this.event.recurrence.rules[0].end;

                    if (this.event.recurrence.rules[0].custom) {
                        this.recurrenceType = this.event.recurrence.rules[0].custom.type;
                        this.recurrenceInterval = this.event.recurrence.rules[0].custom.interval;
                        this.sameDay = this.event.recurrence.rules[0].custom["same-time"];

                        // Daily or Yearly event
                        // if (this.recurrenceType && (this.recurrenceType == 'Daily' || this.recurrenceType == 'Yearly')) {

                        // }

                        // Weekly event
                        if (this.recurrenceType && this.recurrenceType == "Weekly") {
                            this.recurrenceInterval = this.event.recurrence.rules[0].custom.interval;

                            if (this.event.recurrence.rules[0].custom.week.day.includes("1")) {
                                this.d1 = true;
                            }
                            if (this.event.recurrence.rules[0].custom.week.day.includes("2")) {
                                this.d2 = true;
                            }
                            if (this.event.recurrence.rules[0].custom.week.day.includes("3")) {
                                this.d3 = true;
                            }
                            if (this.event.recurrence.rules[0].custom.week.day.includes("4")) {
                                this.d4 = true;
                            }
                            if (this.event.recurrence.rules[0].custom.week.day.includes("5")) {
                                this.d5 = true;
                            }
                            if (this.event.recurrence.rules[0].custom.week.day.includes("6")) {
                                this.d6 = true;
                            }
                            if (this.event.recurrence.rules[0].custom.week.day.includes("7")) {
                                this.d7 = true;
                            }
                        }

                        if (this.recurrenceType && this.recurrenceType == "Monthly") {
                            this.monthNumber = this.event.recurrence.rules[0].custom.month.number;
                            this.monthDay = this.event.recurrence.rules[0].custom.month.day;
                        }
                    }
                }
            } else {
                this.title = this.eventWithUuid.name;
                this.eventDescription = this.eventWithUuid.description;
                this.hgEventStatus = this.eventWithUuid.status;

                if (this.eventWithUuid.recurrence) {
                    if (!this.eventWithUuid.recurrence.on_different_date) {
                        this.sameDay = "yes";
                    } else {
                        this.sameDay = "no";
                    }
                } else if (this.eventWithUuid.parent[0]) {
                    if (!JSON.parse(this.eventWithUuid.parent[0].recurrence).on_different_date) {
                        this.sameDay = "yes";
                    } else {
                        this.sameDay = "no";
                    }
                }

                if (this.eventWithUuid.recurrence) {
                    if (this.eventWithUuid.recurrence.frequency === "weekly") {
                        if (this.eventWithUuid.recurrence.day_in_week == 1) {
                            this.d1 = true;
                        } else if (this.eventWithUuid.recurrence.day_in_week == 2) {
                            this.d2 = true;
                        } else if (this.eventWithUuid.recurrence.day_in_week == 3) {
                            this.d3 = true;
                        } else if (this.eventWithUuid.recurrence.day_in_week == 4) {
                            this.d4 = true;
                        } else if (this.eventWithUuid.recurrence.day_in_week == 5) {
                            this.d5 = true;
                        } else if (this.eventWithUuid.recurrence.day_in_week == 6) {
                            this.d6 = true;
                        } else if (this.eventWithUuid.recurrence.day_in_week == 0) {
                            this.d7 = true;
                        }
                    } else if (this.eventWithUuid.recurrence.frequency === "monthly") {
                        if (this.eventWithUuid.recurrence.day_in_week === 0) {
                            this.monthDay = 7;
                        } else {
                            this.monthDay = this.eventWithUuid.recurrence.day_in_week;
                        }
                    }
                }

                if (this.eventWithUuid.parent[0]) {
                    if (JSON.parse(this.eventWithUuid.parent[0].recurrence).frequency === "weekly") {
                        if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week == 1) {
                            this.d1 = true;
                        } else if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week == 2) {
                            this.d2 = true;
                        } else if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week == 3) {
                            this.d3 = true;
                        } else if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week == 4) {
                            this.d4 = true;
                        } else if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week == 5) {
                            this.d5 = true;
                        } else if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week == 6) {
                            this.d6 = true;
                        } else if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week == 0) {
                            this.d7 = true;
                        }
                    } else if (JSON.parse(this.eventWithUuid.parent[0].recurrence).frequency === "monthly") {
                        if (JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week === 0) {
                            this.monthDay = 7;
                        } else {
                            this.monthDay = JSON.parse(this.eventWithUuid.parent[0].recurrence).day_in_week;
                        }
                    }
                }

                if (this.eventWithUuid.recurrence && this.eventWithUuid.recurrence.every) {
                    this.monthNumber = this.eventWithUuid.recurrence.every;
                }

                if (this.eventWithUuid.parent[0] && JSON.parse(this.eventWithUuid.parent[0].recurrence).every) {
                    this.monthNumber = JSON.parse(this.eventWithUuid.parent[0].recurrence).every;
                }

                if (this.eventWithUuid.recurrence) {
                    this.recurrenceType = this.eventWithUuid.recurrence.frequency;
                    this.recurrenceInterval = parseInt(this.eventWithUuid.recurrence.interval) || 1;
                    this.recurrenceEnd = this.eventWithUuid.recurrence.ends_on;
                    this.endsAfter = this.eventWithUuid.recurrence.ends_after;
                }

                if (this.eventWithUuid.metadata && this.eventWithUuid.metadata.hide_end_time === true) {
                    this.hideEndTime = true;
                }
                if (this.eventWithUuid.metadata && this.eventWithUuid.metadata.hide_location === true) {
                    this.hideLocation = true;
                } else {
                    this.hideLocation = false;
                }

                if (this.eventWithUuid.parent[0]) {
                    this.recurrenceType = JSON.parse(this.eventWithUuid.parent[0].recurrence).frequency;
                    this.recurrenceInterval = JSON.parse(this.eventWithUuid.parent[0].recurrence).interval || 1;
                    this.recurrenceEnd = JSON.parse(this.eventWithUuid.parent[0].recurrence).ends_on;
                    this.endsAfter = parseInt(JSON.parse(this.eventWithUuid.parent[0].recurrence).ends_after);
                }

                if (this.eventWithUuid.image_url) {
                    this.hgEventImage = {
                        url: this.eventWithUuid.image_url,
                    };
                }

                if (this.eventWithUuid.categories) {
                    this.hgSelectedCategories = this.eventWithUuid.categories;
                }
                this.start_date = new Date(this.eventWithUuid.starts_at).toLocaleDateString("en-US", {
                    timeZone: this.jurisdiction.timezone,
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                });

                this.end_date = new Date(this.eventWithUuid.ends_at).toLocaleDateString("en-US", {
                    timeZone: this.jurisdiction.timezone,
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                });

                this.start_time = {
                    hours: new Date(this.eventWithUuid.starts_at).toLocaleTimeString("en-US", {
                        timeZone: this.jurisdiction.timezone,
                        hour12: false,
                        hour: "numeric",
                    }),
                    minutes: new Date(this.eventWithUuid.starts_at).toLocaleTimeString("en-US", {
                        minute: "numeric",
                    }),
                };

                this.end_time = {
                    hours: new Date(this.eventWithUuid.ends_at).toLocaleTimeString("en-US", {
                        timeZone: this.jurisdiction.timezone,
                        hour12: false,
                        hour: "numeric",
                    }),
                    minutes: new Date(this.eventWithUuid.ends_at).toLocaleTimeString("en-US", {
                        minute: "numeric",
                    }),
                };

                if (
                    new Date(this.eventWithUuid.starts_at).toLocaleTimeString("en-US", {
                        timeZone: this.jurisdiction.timezone,
                    }) === "12:00:00 AM" &&
                    new Date(this.eventWithUuid.ends_at).toLocaleTimeString("en-US", {
                        timeZone: this.jurisdiction.timezone,
                    }) === "11:59:00 PM"
                ) {
                    this.allDay = true;
                }

                if (this.eventWithUuid.venues) {
                    this.heyGovBlockedVenues = this.eventWithUuid.venues || null;
                }
                if (this.eventWithUuid.recurrence && this.eventWithUuid.recurrence.frequency === "no") {
                    this.recurrenceType = "";
                }
            }
        },
        async setCategories() {
            if (!this.hasHeyGovEvents) {
                let res = await this.getEventCategories();
                this.categories = await res.data.categories;
            }
        },
        async setHeyGovVenues() {
            let res = await this.getHeyGovVenues();
            let self = this;

            // :options="[
            //         { value: 'receive_email', label: 'Email' },
            //         { value: 'receive_text_message', label: 'Text message' },
            //         { value: 'website_ticker', label: 'Website Ticker' },
            //     ]"

            res.forEach(function (venue) {
                let tmp = {};

                tmp.value = venue.id;
                tmp.label = venue.name;
                tmp.uuid = venue.uuid;

                self.heyGovVenues.push(tmp);
            });
        },
        async getHeygovJurisdiction() {
            this.jurisdiction = await this.heyGovJurisdiction();
            if (this.jurisdiction) {
                await this.getHeyGovCategories();
            }
        },
        async getHeyGovCategories() {
            let res = await this.axios.get(`${this.apiRoute}/${this.domain}/events/categories`);
            if (res) {
                this.hgCategories = res.data;
            }
        },
        async handleFileUpload(event) {
            event.preventDefault();
            if (event.target.files[0]) {
                this.imageUploads = true;
                this.file = event.target.files[0];

                if (!this.hasHeyGovEvents) {
                    // Tw wp site
                    let res = await this.uploadFile(this.file);
                    this.featuredMedia = await res.data;
                } else {
                    // HeyGov
                    this.file = event.target.files[0];
                    let input = document.querySelector("#hg-image-upload");
                    let file = input.files;

                    if (file && file[0]) {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            this.hgEventImage = {
                                url: e.target.result,
                            };
                        };
                        reader.readAsDataURL(file[0]);
                        this.$emit("input", file[0]);
                    }
                }

                this.imageUploads = false;

                if (this.mediaLibOpened) {
                    this.$refs.mediaLibrary.setMediaLibrary();
                }
            } else {
                console.log("Didn't match condition");
            }
        },
        async uploadHgImage(event) {
            const formData = new FormData();
            formData.append("file", this.file);
            try {
                await this.axios.post(`${this.apiRoute}/${this.domain}/events/${event?.pid ?? event?.id}/image?apiKey=${this.apiKey}`, formData).then((data) => {
                    this.hgEventImage = data.data;
                    event.image_url = data.data.image_url;
                });
            } catch (error) {
                console.log(error);
            }
        },
        async deleteHgImage(uuid) {
            try {
                await this.axios.delete(`${this.apiRoute}/${this.domain}/events/${uuid}/image?apiKey=${this.apiKey}`);
            } catch (error) {
                console.log(error);
            }
            this.hgEventImage = null;
        },
        async storeNewEvent() {
            this.spinner = true;
            let categories = [];
            categories.push(this.category);

            let mediaId = null;
            let res;
            if (this.featuredMedia) {
                mediaId = this.featuredMedia.id;
            }

            this.event.comment_status = "closed";
            this.event.ping_status = "closed";

            this.event.title = this.title;
            this.event.description = this.eventDescription;
            this.event.categories = categories;
            this.event.all_day = this.allDay;
            this.event.status = "publish";
            //this.event.start_date = "2022-03-30 01:00:03"; //2022-03-31 02:00:03
            //this.event.start_date = this.moment(this.start_date).format('YYYY-MM-DD HH:mm:ss'); //2022-03-31 02:00:03
            this.event.start_date = this.moment(this.start_date).format("YYYY-MM-DD") + this.moment(this.start_time).format(" HH:mm:ss"); //2022-03-31 02:00:03
            //this.event.end_date = "2022-03-31 02:00:03"; //2022-03-30 01:00:03
            //this.event.end_date = this.moment(this.end_date).format('YYYY-MM-DD HH:mm:ss'); //2022-03-30 01:00:03
            this.event.end_date = this.moment(this.end_date).format("YYYY-MM-DD") + this.moment(this.end_time).format(" HH:mm:ss"); //2022-03-30 01:00:03
            this.event.recurrence = {};

            this.event.recurrence.type = this.recurrenceType;

            if (this.recurrenceType && (this.recurrenceType == "Daily" || this.recurrenceType == "Weekly" || this.recurrenceType == "Monthly" || this.recurrenceType == "Yearly")) {
                this.event.recurrence.interval = this.recurrenceInterval;
            }

            if (this.recurrenceType && this.recurrenceType == "Monthly") {
                this.event.recurrence.month = {};
                this.event.recurrence.month["same-day"] = this.sameDay;
                this.event.recurrence.month.number = this.monthNumber;
                this.event.recurrence.month.day = this.monthDay;
            }

            if (this.recurrenceType && this.recurrenceType == "Weekly") {
                this.event.recurrence.week = {};
                this.event.recurrence.week.days = {};

                this.event.recurrence.week.days.d1 = this.d1;
                this.event.recurrence.week.days.d2 = this.d2;
                this.event.recurrence.week.days.d3 = this.d3;
                this.event.recurrence.week.days.d4 = this.d4;
                this.event.recurrence.week.days.d5 = this.d5;
                this.event.recurrence.week.days.d6 = this.d6;
                this.event.recurrence.week.days.d7 = this.d7;
            }

            if (this.recurrenceType && this.recurrenceEnd) {
                this.event.end = this.moment(this.recurrenceEnd).format("YYYY-MM-DD");
            }
            this.event.image = mediaId;

            if (this.hasHeyGovWithVenues) {
                this.event.heygov_venues = this.heyGovBlockedVenues.join();
            }
            // HEYGOV
            if (this.hasHeyGovEvents) {
                // Save
                if (!this.editingEventId && !this.editing) {
                    const eventStartDate = this.moment(this.start_date).format("YYYY-MM-DD");
                    const eventEndDate = this.moment(this.end_date).format("YYYY-MM-DD");
                    let eventStartTime = this.moment(this.start_time).format("HH:mm:ss");
                    let eventEndTime = this.moment(this.end_time).format("HH:mm:ss");

                    if (this.allDay) {
                        eventStartTime = "00:00:00";
                        eventEndTime = "23:59:00";
                    }
                    if (this.hgSelectedCategories[0] === "") {
                        this.hgSelectedCategories = [];
                    }

                    this.eventObject = {
                        name: this.title,
                        description: this.eventDescription,
                        categories: this.hgSelectedCategories,
                        status: this.hgEventStatus,
                        starts_at_local: `${eventStartDate} ${eventStartTime}`,
                        ends_at_local: `${eventEndDate} ${eventEndTime}`,
                        image_url: this.hgImageLink ? this.hgImageLink : "",
                    };

                    if (this.hideEndTime) {
                        this.eventObject.metadata = {
                            hide_end_time: true,
                        };
                    } else {
                        this.eventObject.metadata = {
                            hide_end_time: false,
                        };
                    }
                    if (this.hideLocation) {
                        this.eventObject.metadata = {
                            ...this.eventObject.metadata,
                            hide_location: true,
                        };
                    } else {
                        this.eventObject.metadata = {
                            ...this.eventObject.metadata,
                            hide_location: false,
                        };
                    }

                    if (this.heyGovBlockedVenues && this.heyGovBlockedVenues.length) {
                        this.eventObject.venues = this.heyGovBlockedVenues || null;
                    }

                    let every;
                    if (this.monthNumber) {
                        every = this.monthNumber.toLocaleLowerCase();
                    }

                    let dayInWeek;
                    if (this.d1) {
                        dayInWeek = 1;
                    } else if (this.d2) {
                        dayInWeek = 2;
                    } else if (this.d3) {
                        dayInWeek = 3;
                    } else if (this.d4) {
                        dayInWeek = 4;
                    } else if (this.d5) {
                        dayInWeek = 5;
                    } else if (this.d6) {
                        dayInWeek = 6;
                    } else if (this.d7) {
                        dayInWeek = 0;
                    }

                    let dayInMonth;
                    if (this.monthDay) {
                        dayInMonth = parseInt(this.monthDay);
                    }

                    if (this.recurrenceType) {
                        this.eventObject.recurrence = {
                            frequency: this.recurrenceType,
                            interval: parseInt(this.recurrenceInterval),
                            ends_on: this.recurrenceEnd ? this.moment(this.recurrenceEnd).format("YYYY-MM-DD") : "",
                            ends_after: this.endsAfter ? this.endsAfter : "",
                        };
                        if (this.sameDay === "yes") {
                            this.eventObject.recurrence.on_different_date = false;
                            this.eventObject.recurrence.every = null;
                        } else if (this.sameDay === "no") {
                            this.eventObject.recurrence.on_different_date = true;
                            this.eventObject.recurrence.every = every;
                        }
                        if (this.eventObject.recurrence.frequency === "weekly") {
                            this.eventObject.recurrence.day_in_week = dayInWeek;
                        }
                        if (this.eventObject.recurrence.frequency === "monthly") {
                            this.eventObject.recurrence.day_in_week = dayInMonth;
                        }
                    }

                    // Create new HG event
                    res = await this.axios.post(`${this.apiRoute}/${this.domain}/events?apiKey=${this.apiKey}`, this.eventObject);

                    // Upload image
                    if (this.file) {
                        await this.uploadHgImage(res.data);
                    }

                    if (this.siteType == "cms6") {
                        this.axios.post(
                            process.env.VUE_APP_JUNIPER_API + `clients/regenerate/events`,
                            {},
                            {
                                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                                headers: this.headers,
                            }
                        );
                    } else if (this.siteType == "wordpress") {
                        this.heyGovEventsClearCache();
                    }

                    // Update
                } else {
                    this.start_date = this.moment(this.start_date).format("YYYY-MM-DD");
                    this.start_time = this.moment(this.start_time).format("HH:mm:ss");
                    this.end_date = this.moment(this.end_date).format("YYYY-MM-DD");
                    this.end_time = this.moment(this.end_time).format("HH:mm:ss");

                    if (this.allDay) {
                        this.start_time = "00:00:00";
                        this.end_time = "23:59:00";
                    }

                    let updateEventObject = {};

                    // Update hg event
                    if (this.title && this.eventWithUuid.name != this.title) {
                        updateEventObject = {
                            ...updateEventObject,
                            name: this.title,
                        };
                    }

                    if (this.hgEventStatus && this.eventWithUuid.status != this.hgEventStatus) {
                        updateEventObject = {
                            ...updateEventObject,
                            status: this.hgEventStatus,
                        };
                    }

                    if (this.eventDescription && this.eventWithUuid.description !== this.eventDescription) {
                        updateEventObject.description = this.eventDescription;
                    } else if (this.eventWithUuid.description !== this.eventDescription) {
                        updateEventObject.description = null;
                    }

                    if (this.hgSelectedCategories[0] === "") {
                        this.hgSelectedCategories = [];
                    }

                    if (this.hgSelectedCategories && this.hgSelectedCategories.length) {
                        updateEventObject = {
                            ...updateEventObject,
                            categories: this.hgSelectedCategories,
                        };
                    }

                    if (
                        this.moment(this.eventWithUuid.starts_at_local).format("YYYY-MM-DD") !== this.start_date ||
                        new Date(this.eventWithUuid.starts_at).toLocaleTimeString("en-US", { hour12: false, timeZone: this.jurisdiction.timezone }) !== this.start_time
                    ) {
                        updateEventObject.starts_at_local = `${this.start_date} ${this.start_time}`;
                    }

                    if (
                        this.moment(this.eventWithUuid.ends_at_local).format("YYYY-MM-DD") !== this.end_date ||
                        new Date(this.eventWithUuid.ends_at).toLocaleTimeString("en-US", { hour12: false, timeZone: this.jurisdiction.timezone }) !== this.end_time
                    ) {
                        updateEventObject.ends_at_local = `${this.end_date} ${this.end_time}`;
                    }

                    if (updateEventObject.ends_at_local && !updateEventObject.starts_at_local) {
                        updateEventObject.starts_at_local = `${this.start_date} ${this.start_time}`;
                    }

                    if (updateEventObject.starts_at_local && !updateEventObject.ends_at_local) {
                        updateEventObject.ends_at_local = `${this.end_date} ${this.end_time}`;
                    }

                    if (this.heyGovBlockedVenues) {
                        updateEventObject = {
                            ...updateEventObject,
                            venues: this.heyGovBlockedVenues,
                        };
                    }

                    if (this.hideEndTime) {
                        updateEventObject.metadata = {
                            ...updateEventObject.metadata,
                            hide_end_time: true,
                        };
                    } else {
                        updateEventObject.metadata = {
                            ...updateEventObject.metadata,
                            hide_end_time: false,
                        };
                    }

                    if (this.hideLocation) {
                        updateEventObject.metadata = {
                            ...updateEventObject.metadata,
                            hide_location: true,
                        };
                        updateEventObject.location = null;
                    } else {
                        updateEventObject.metadata = {
                            ...updateEventObject.metadata,
                            hide_location: false,
                        };
                    }

                    let every;
                    if (this.monthNumber) {
                        every = this.monthNumber.toLocaleLowerCase();
                    }

                    let dayInWeek;
                    if (this.d1) {
                        dayInWeek = 1;
                    } else if (this.d2) {
                        dayInWeek = 2;
                    } else if (this.d3) {
                        dayInWeek = 3;
                    } else if (this.d4) {
                        dayInWeek = 4;
                    } else if (this.d5) {
                        dayInWeek = 5;
                    } else if (this.d6) {
                        dayInWeek = 6;
                    } else if (this.d7) {
                        dayInWeek = 0;
                    }

                    let dayInMonth;
                    if (this.monthDay) {
                        dayInMonth = parseInt(this.monthDay);
                    }

                    if (this.eventWithUuid.recurrence) {
                        if (
                            (this.recurrenceType && this.eventWithUuid.recurrence.frequency !== this.recurrenceType) ||
                            (this.recurrenceInterval && this.eventWithUuid.recurrenceInterval !== this.recurrenceInterval) ||
                            (this.recurrenceInterval && this.eventWithUuid.recurrence.ends_on !== this.recurrenceEnd) ||
                            (this.endsAfter && this.eventWithUuid.recurrence.ends_after && this.eventWithUuid.recurrence.ends_after !== this.endsAfter)
                        ) {
                            updateEventObject.recurrence = {
                                ...updateEventObject.reccurence,
                                frequency: this.recurrenceType,
                                interval: this.recurrenceInterval ? parseInt(this.recurrenceInterval) : 1,
                                ends_on: this.recurrenceEnd ? this.moment(this.recurrenceEnd).format("YYYY-MM-DD") : "",
                                ends_after: this.endsAfter ? this.endsAfter : "",
                            };
                        } else if (!this.recurrenceType) {
                            updateEventObject.recurrence = {
                                ...updateEventObject.reccurence,
                                frequency: "no",
                            };
                        }
                    } else if (this.eventWithUuid.parent[0]) {
                        let recurrence = JSON.parse(this.eventWithUuid.parent[0].recurrence);
                        if (
                            (this.recurrenceType && recurrence && recurrence.frequency !== this.recurrenceType) ||
                            (this.recurrenceInterval && recurrence && recurrence.interval !== this.recurrenceInterval) ||
                            (this.recurrenceEnd && recurrence && recurrence.ends_on !== this.recurrenceEnd) ||
                            (this.endsAfter && recurrence && recurrence.ends_after !== this.endsAfter) ||
                            (dayInWeek && recurrence && recurrence.day_in_week !== dayInWeek) ||
                            (dayInMonth && recurrence && recurrence.day_in_week !== dayInMonth)
                        ) {
                            updateEventObject.recurrence = {
                                ...updateEventObject.reccurence,
                                frequency: this.recurrenceType,
                                interval: this.recurrenceInterval ? parseInt(this.recurrenceInterval) : 1,
                                ends_on: this.recurrenceEnd ? this.moment(this.recurrenceEnd).format("YYYY-MM-DD") : "",
                                ends_after: this.endsAfter ? this.endsAfter : "",
                            };
                            updateEventObject.recurrence = {
                                ...updateEventObject.recurrence,
                                day_in_week: updateEventObject.recurrence.frequency === "monthly" ? dayInWeek : dayInMonth,
                            };
                        } else if (!this.recurrenceType) {
                            updateEventObject.recurrence = {
                                ...updateEventObject.reccurence,
                                frequency: "no",
                            };
                        }
                    } else if (this.recurrenceType && (!this.eventWithUuid.recurrence || !this.eventWithUuid.parent[0])) {
                        updateEventObject.recurrence = {
                            ...updateEventObject.reccurence,
                            frequency: this.recurrenceType,
                            interval: this.recurrenceInterval ? this.recurrenceIntervals : 1,
                            ends_on: this.recurrenceEnd ? this.moment(this.recurrenceEnd).format("YYYY-MM-DD") : "",
                        };
                    }
                    if (!this.recurrenceType) {
                        updateEventObject.recurrence = {
                            ...updateEventObject.reccurence,
                            frequency: "no",
                        };
                    }

                    if (updateEventObject.recurrence && updateEventObject.recurrence.frequency === "weekly") {
                        updateEventObject.recurrence = {
                            ...updateEventObject.recurrence,
                            day_in_week: dayInWeek,
                        };
                    }
                    if (updateEventObject.recurrence && updateEventObject.recurrence.frequency === "monthly") {
                        updateEventObject.recurrence = {
                            ...updateEventObject.recurrence,
                            day_in_week: dayInMonth,
                        };
                    }

                    if (updateEventObject.recurrence && updateEventObject.recurrence.frequency) {
                        if (this.sameDay === "yes") {
                            updateEventObject.recurrence = {
                                ...updateEventObject.recurrence,
                                on_different_date: false,
                                every: null,
                            };
                        } else if (this.sameDay === "no") {
                            updateEventObject.recurrence = {
                                ...updateEventObject.recurrence,
                                on_different_date: true,
                                every: every,
                            };
                        }
                    }

                    if (this.eventWithUuid.type == "single" && updateEventObject.recurrence.frequency && updateEventObject.recurrence.interval) {
                        this.editType = "all";
                    }

                    const uuid = this.eventWithUuid.source_event ?? this.eventWithUuid.pid ?? this.eventWithUuid.uuid ?? this.eventWithUuid.id;

                    if (this.editType === "all") {
                        if (!updateEventObject.recurrence) {
                            updateEventObject = {
                                ...updateEventObject,
                                recurrence: this.eventWithUuid.recurrence || JSON.parse(this.eventWithUuid.parent[0].recurrence),
                            };
                        }
                        if (updateEventObject.starts_at_local && updateEventObject.ends_at_local && this.eventWithUuid.type === "recurrence") {
                            this.start_date = new Date(this.eventWithUuid.parent[0].starts_at_local).toLocaleDateString("en-US", {
                                timeZone: this.jurisdiction.timezone,
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            });

                            this.end_date = new Date(this.eventWithUuid.parent[0].ends_at_local).toLocaleDateString("en-US", {
                                timeZone: this.jurisdiction.timezone,
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            });
                            this.start_date = this.moment(this.start_date).format("YYYY-MM-DD");
                            this.end_date = this.moment(this.end_date).format("YYYY-MM-DD");
                            updateEventObject.starts_at_local = `${this.start_date} ${this.start_time}`;
                            updateEventObject.ends_at_local = `${this.end_date} ${this.end_time}`;
                        }
                        res = await this.axios.put(`${this.apiRoute}/${this.domain}/events/${uuid}?apiKey=${this.apiKey}`, updateEventObject);
                    } else if (this.editType === "partial") {
                        res = await this.axios.put(`${this.apiRoute}/${this.domain}/events/${this.eventWithUuid.uuid ?? this.eventWithUuid.id}?apiKey=${this.apiKey}&${this.editType}=1`, updateEventObject);
                    } else {
                        // For "single" update, remove the recurrence payload
                        if (updateEventObject.recurrence && this.editType === "single") {
                            delete updateEventObject.recurrence;
                        }
                        res = await this.axios.put(`${this.apiRoute}/${this.domain}/events/${this.eventWithUuid.uuid ?? this.eventWithUuid.id}?apiKey=${this.apiKey}&${this.editType}=1`, updateEventObject);
                    }

                    //Upload image
                    if (this.file) {
                        await this.uploadHgImage(res.data);
                    }

                    if (this.siteType == "cms6") {
                        this.axios.post(
                            process.env.VUE_APP_JUNIPER_API + `clients/regenerate/events`,
                            {},
                            {
                                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                                headers: this.headers,
                            }
                        );
                    } else if (this.siteType == "wordpress") {
                        this.heyGovEventsClearCache();
                    }
                }
            } else {
                if (this.editingEventId) {
                    const payload = this.event;
                    if (!isNaN(this.event.slug)) {
                        payload.id = this.event.slug;
                    }
                    res = await this.updateEvent(payload);
                } else {
                    res = await this.saveEvent(this.event);
                }
            }

            this.spinner = false;
            this.editType = "single";

            if (res.status === 201 || res.status === 200) {
                this.modal.hide();
                this.$emit("eventSaved");
            } else {
                this.sendError = true;
            }
        },
        mediaLib(event, chooseFeaturedImg = false) {
            if (this.mediaLibOpened) {
                this.mediaLibOpened = false;
            } else {
                let allowMulti = !chooseFeaturedImg;
                this.$refs.mediaLibrary.mediaLibOpen(true, chooseFeaturedImg, allowMulti, chooseFeaturedImg);
                this.mediaLibOpened = true;
            }
        },
        tinyMCEMedLib(event) {
            // console.log(event)
            if (event.command === "openMediaLib") {
                this.mediaLib(false);
            }
        },
        handleMediaInsert(content) {
            getTinymce().activeEditor.insertContent(content);
        },
        handleSetFeaturedImage(image) {
            this.featuredMedia = image;
        },
        generateId(prefix, length) {
            if (!prefix) {
                throw new Error("prefix is required");
            }

            const unique = (Math.random() + 1).toString(36).substring(2);

            return `${prefix}_${unique.slice(0, length || 20)}`;
        },
        async addNewCategory() {
            let res = await this.axios.post(`${this.apiRoute}/${this.domain}/events/categories?apiKey=${this.apiKey}`, { name: this.newCategory });
            if (res) {
                this.hgCategories.push(res.data);
                this.closeCategoryModal();
            }
        },
        openCategoryModal() {
            this.modal._element.style.opacity = "10%";
            this.$modalCategory.show();
        },
        closeCategoryModal() {
            this.newCategory = "";
            this.$modalCategory.hide();
            this.modal._element.style.opacity = "100%";
        },
        heyGovEventsClearCache() {
            this.axios.get(this.siteUrl + `/wp-json/tw/v1/events-transient-wprocketcache-clear`, {
                headers: this.wpBasicHeaders,
            });
        },
    },
    computed: {
        tinymcInit() {
            return tinymceConfig.config;
        },
        supportsFb() {
            return this.supportsFBPublish();
        },
        hasHeyGovWithVenues() {
            return this.isHeyGovClientWithVenues();
        },
        uuid() {
            return this.generateId("ev", 8);
        },
        siteType() {
            return this.getSiteType();
        },
        siteUrl() {
            return this.getSiteUrl();
        },
    },

    props: {
        editing: Boolean,
        editingEventId: String,
        intervalLabels: Object,
        eventWithUuid: Object,
        // posts: Array,
        // columns: Array,
        // title: String,
        // description: String,
        // contentWrapperClasses: String,
        // withFilter: {
        //     type: Boolean,
        //     default: false
        // }
    },
    mixins: [wordpressMixin, helperMixin, siteMixin, heyGovMixin, userMixin],
};
</script>
<style scoped>
#tw-modal-featured-img-loading-overlay {
    border-radius: 10px;
}
#tw-modal-featured-img-overlay {
    transition: 0.3s;
    background: rgba(255, 255, 255, 0);
}
#tw-modal-featured-img-overlay * {
    opacity: 0;
    transition: 0.3s;
}
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered {
    background: rgba(255, 255, 255, 0.25);
}
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered * {
    opacity: 1;
}
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > span:hover,
#tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > label:hover {
    transform: scale(1.1);
}
#tw-modal-featured-img-overlay > div > span {
    width: 50px;
    height: 50px;
}
.tw-create-post-modal-date-from,
.tw-create-post-modal-date-to,
.tw-create-post-modal-time-from,
.tw-create-post-modal-time-to,
.tw-create-modal-date-recurrence-end {
    top: -10px;
    left: 10px;
}
/* .modal-dialog {
    min-width: 80%;
} */
.collapsed {
    width: 0;
}
.anime {
    /*flex-shrink: 1;*/
    transition: all 300ms ease;
}
.tw-create-modal-media-lib-wrapper {
    border-left: 1px solid #e0e0e0;
}
.modal-xl {
    max-width: 90%;
}
.tw-checkbox label {
    margin-left: 35px;
}
.tw-checkbox label::before {
    top: calc(50% - 12px);
    left: -35px;
}
.tw-checkbox label::after {
    top: calc(50% - 6px);
    left: -29px;
}
select.tw-recurrence-select {
    border-radius: 10px !important;
    padding: 10px;
}
.hg-event-category {
    background-color: #10b981;
    color: white;
}

@media only screen and (min-width: 992px) {
    .modal-lg {
        max-width: 900px;
    }
}
/* @media only screen and (max-width: 991.99px) {
    .modal-dialog {
        min-width: 90%;
    }
} */
.tw-number-input-field {
    border-radius: 10px !important;
    padding: 12px;
}
.tw-button-rounded {
    border-radius: 10px;
}
</style>
